// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery'
import '../css/application'
import '../css/census'
import '../css/cemeteries'
import '../css/descendants'
import '../css/dnas'
import '../css/simple_discussion'
import '../css/welcome'
import '../css/doctors'
import '../css/stylesheet'
import '../css/actiontext.scss'
import '../css/timelines.scss'
import '../css/notebooks.scss'
//import '../css/style.light-blue-500'
// import '../css/style.datepicker'
import '../src/plugins'
import '../src/custom'
import "@fortawesome/fontawesome-free/css/all"

// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';

import './images.js'

require("trix")
require("@rails/actiontext")

// $(function () {
//   $('[data-toggle="tooltip"]').tooltip()
// })

//import flatpickr from "flatpickr"
// require("flatpickr/dist/flatpickr.css")

// document.addEventListener("turbolinks:load", () => {
//   flatpickr("[data-behavior='flatpickr']", {
//     altInput: true,
//     altFormat: "F j, Y",
//     dateFormat: "Y-m-d",
//   })
// })

document.addEventListener("turbolinks:load", () => {
  $('#showdate').datetimepicker()
})


window.Noty = require("noty")
require.context('../images', true)

// document.addEventListener("turbolinks:load", )
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$('#myModal').on('shown.bs.modal', function () {
  $('#myInput').trigger('focus')
})

// document.addEventListener('turbolinks:load', () => {
//   document.addEventListener('click', () => {
//     let element = event.target.closest('.text-content')
//     if (!element) return;
//
//     element.classList.add('d-none')
//     element.nextElementSibling.classList.remove('d-none')
//   })
//
//   document.addEventListener('click', () => {
//     if (!event.target.matches('.cancel')) return;
//
//     event.preventDefault();
//
//     let element = event.target.closest('.form-content')
//
//     element.classList.add('d-none')
//     element.previousElementSibling.classList.remove('d-none')
//   })
// })
//
// document.addEventListener("DOMContentLoaded", function(event) {
//   var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//     return new bootstrap.Popover(popoverTriggerEl)
//   })
//
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//   })
// });

//import "controllers"
