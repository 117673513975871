$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});

$("[data-toggle=popover]").popover();

// works but needs something else
//$('.datepicker').datepicker({ format: 'yyyy', startDate: '-3d' });
// 'mm/dd/yyyy'
